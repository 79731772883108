import * as React from "react";

import { UitkLink } from "@egds/react-core/link";
import { UitkText } from "@egds/react-core/text";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { LinkProps } from "../typings";

const Link = (props: LinkProps) => {
  const { link } = props;
  const { text, url } = link;

  if (!(text && url)) {
    return null;
  }

  return (
    <UitkText inline overflow="truncate">
      <UitkLink>
        <TrackedLink moduleName="link-juice" href={url} linkName={text}>
          {text}
        </TrackedLink>
      </UitkLink>
    </UitkText>
  );
};

export default Link;
