import * as React from "react";

import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkLayoutGrid, UitkLayoutGridColumnRow } from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";

import Link from "./components/Link";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { LinkJuiceCategorySectionProps } from "./typings";

const LinkJuiceCategorySection = (props: LinkJuiceCategorySectionProps) => {
  const { category, collapsed, displayColumns } = props;
  const { name, links } = category;
  if (!(name && links && links.length)) {
    return null;
  }

  const DEFAULT_MOBILE_DISPLAY_COLUMNS = 1;
  const columns = {
    small: DEFAULT_MOBILE_DISPLAY_COLUMNS as UitkLayoutGridColumnRow,
    large: Number(displayColumns) as UitkLayoutGridColumnRow,
  };

  const [isExpanded, setIsExpanded] = React.useState(!collapsed);

  const toggleExpansion = (): void => {
    setIsExpanded(!isExpanded);
  };
  const expandoKeyboardPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleExpansion();
    }
  };

  const keyHelper = new ItemKeyHelper("LinkJuiceLinks");

  return (
    <UitkSpacing margin="one">
      <UitkCard>
        <div
          className="CallToAction"
          tabIndex={0}
          role="button"
          aria-expanded={isExpanded}
          onClick={toggleExpansion}
          data-testid="expando-toggle"
          onKeyDown={expandoKeyboardPress}
        >
          <UitkCardContentSection padded>
            <UitkLayoutFlex>
              <UitkLayoutFlexItem grow={1}>
                <UitkText size={400} weight="bold" theme="default">
                  {name}
                </UitkText>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem alignSelf="center" grow={0}>
                <UitkIcon name={isExpanded ? "expand_less" : "expand_more"} size={UitkIconSize.SMALL} />
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkCardContentSection>
        </div>
        <UitkCardContentSection
          padded={["blockend", "inlinestart", "inlineend"]}
          className={`${isExpanded ? "" : "is-hidden"}`}
        >
          <UitkSpacing padding={{ blockend: "one", inline: "one" }}>
            <div>
              <UitkLayoutGrid columns={columns} space="one">
                {links.map((link) => (
                  <Link key={keyHelper.next()} link={link} />
                ))}
              </UitkLayoutGrid>
            </div>
          </UitkSpacing>
        </UitkCardContentSection>
      </UitkCard>
    </UitkSpacing>
  );
};

export default LinkJuiceCategorySection;
